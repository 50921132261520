import { Component } from '@angular/core';
import { RouterLink } from "@angular/router";

@Component({
  selector: 'b2b-padel-footer',
  templateUrl: './footer.component.html',
  standalone: true,
  imports: [
    RouterLink
  ],
  styles: ``
})
export class FooterComponent {

}
