<div class="surface-900 pt-4 pb-5 px-5 lg:py-4 md:px-6 lg:px-8 flex flex-column lg:flex-row justify-content-between align-items-start md:align-items-center">
  <div class="flex align-items-center flex-order-1 lg:flex-order-0 mt-6 md:mt-0">
    <a class="text-400">© 2023, KreisViereck UG</a>
  </div>

  <ul class="list-none p-0 mb-0 flex flex-column md:flex-row flex-order-0 lg:flex-order-1 md:mt-4 lg:mt-0">
    <li class="mr-4 mt-3 lg:mt-0">
      <a tabindex="0" class="cursor-pointer text-400" routerLink="/contact-us">Contact</a>
    </li>
    <li class="mr-4 mt-3 lg:mt-0">
      <a tabindex="0" class="cursor-pointer text-400" routerLink="/privacy-policy">Privacy Policy</a>
    </li>
    <li class="mr-4 mt-3 lg:mt-0">
      <a tabindex="0" class="cursor-pointer text-400" routerLink="/terms-of-service">Terms of Service</a>
    </li>
    <li class="mr-4 mt-3 lg:mt-0">
      <a tabindex="0" class="cursor-pointer text-400" routerLink="/imprint">Imprint</a>
    </li>
  </ul>
</div>
